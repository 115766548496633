:root {
    --background-color: white;
    --text-color: black;
    --light-color: #f5f5f5;
    --modal-overlay: rgba(0, 0, 0, 0.5);
    --absent-color: #787c7e;
    --present-color: #c9b458;
    --correct-color: #6aaa64;
    --key-color: #d3d6da;
    --tile-border: 2px solid #d3d6da;
    --tile-border-filled: 2px solid #7d7f81;
}

html {
    height: 100%;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: var(--background-color);
    color: var(--text-color);
}

body.nightmode {
    --background-color: black;
    --text-color: rgb(190, 190, 190);
    --modal-overlay: rgba(100, 100, 100, 0.7);
    --absent-color: #3a3a3c;
    --correct-color: #538d4e;
    --present-color: #b59f3b;
    --key-color: #818384;
    --tile-border: 2px solid #3a3a3c;
    --tile-border-filled: 2px solid #97979c;
}

body.colorblind {
    --present-color: #85c0f9;
    --correct-color: #f5793a;
}

/* ANIMATIONS */

@keyframes bounce {
    from {
        transform: scale(1.1);
    }
    to {
        transform: scale(1);
    }
}

@keyframes turn {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(90deg);
    }
}

@keyframes nurt {
    from {
        transform: rotateX(90deg);
    }
    to {
        transform: rotateX(0deg);
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-5px);
    }
    30% {
        transform: translateX(5px);
    }
    50% {
        transform: translateX(-5px);
    }
    70% {
        transform: translateX(5px);
    }
    90% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}

#app {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    justify-content: space-between;
    max-width: 460px;
    margin: 0 auto;
}

header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
}

.nightmode header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

header h1 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

header .info {
    position: absolute;
    font-size: 1.3rem;
    font-weight: 400;
    right: 0;
    top: 10px;
}

header .info svg {
    width: 1.3rem;
    height: 1.3rem;
}

header .game-mode {
    position: absolute;
    font-size: 1.3rem;;
    font-weight: 400;
    left: 0;
    top: 10px;
    color: #999;
    cursor: pointer;
}

.board {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 5px;
    padding: 10px;
    box-sizing: border-box;
    max-width: 330px;
    width: 100%;
    margin: auto;
}

@media screen and (max-height: 630px) {
    .board {
       width: 45vh;
    }
}

.row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
}

.row.shake {
    animation: shake 0.3s;
    animation-iteration-count: 1;
}

.tile {
    border: var(--tile-border);
    width: 100%;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    box-sizing: border-box;
    text-align: center;
    color: var(--tile-text-color);
    text-transform: uppercase;
    user-select: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
}

.tile.bounce {
    animation-name: bounce;
    animation-duration: 0.15s;
    animation-iteration-count: 1;
    border: var(--tile-border-filled);
}

.tile.filled {
    border: var(--tile-border-filled);
}

.tile::before {
    content: "";
    display: inline-block;
    padding-bottom: 100%;
}

.tile.turn {
    animation-name: turn;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.tile.nurt {
    animation-name: nurt;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.tile.present {
    border: 0;
    background-color: var(--present-color);
    color: #FFF;
}

.tile.absent {
    border: 0;
    background-color: var(--absent-color);
    color: #FFF;
}

.tile.correct {
    border: 0;
    background-color: var(--correct-color);
    color: #FFF;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-overlay);
    z-index: 10;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    max-width: calc(100% - 2rem);
    background-color: var(--background-color);
    border-radius: 0.5rem;
    padding: 3rem 1rem 2rem;
    box-sizing: border-box;
}

.close-modal {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: var(--text-color);
}

.INFO-modal {
    font-size: 1rem;
}

.hg-button {
    text-transform: uppercase;
    height: 58px !important;
    border-radius: 4px;
    background-color: var(--key-color) !important;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none !important;
    border-bottom: 0 !important;
}

.hg-button.used {
    background-color: var(--absent-color) !important;
}

.hg-button.present {
    background-color: var(--present-color) !important;
}

.hg-button.correct {
    background-color: var(--correct-color) !important;
}

.hg-button.hg-button-backspace {
    font-size: 1.3rem;
}

.simple-keyboard {
    background-color: transparent !important;
}

.btn {
    border-radius: 0;
    background-color: #d3d6da;
    color: #000;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: 0;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
}

.countdown {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 1rem;
}

.menu-voice {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid gray;
    margin-bottom: 1rem;
}

.menu-voice h4 {
    margin: 0;
}

.switch {
    width: 2.4rem;
    background: var(--correct-color);
    height: 1.2rem;
    border-radius: 0.6rem;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.switch-container > input[type=checkbox] {
    display: none;
}

.switch-container > input[type=checkbox] + .switch {
    background-color:rgba(100, 100, 100, 0.7);
}

.switch-container > input[type=checkbox] + .switch span {
    display: block;
    position: absolute;
    height: 0.8rem;
    width: 0.8rem;
    background-color: white;
    top: 0.2rem;
    left: 0.2rem;
    border-radius: 50%;
    transition: transform 0.3s;
}

.switch-container > input[type=checkbox]:checked + .switch {
    background-color: var(--correct-color);
}

.switch-container > input[type=checkbox]:checked + .switch span {
    transform: translateX(1.2rem);
}

.stats-title {
    text-align: center;
    color: var(--text-color);
    font-size: 1.3rem;
    font-weight: bold;
}

.stat-row {
    display: flex;
    margin: auto;
    justify-content: center;
}

.stat-item {
    margin: 0.3rem;
    width: 3.5rem;
}

.stat-value {
    text-align: center;
    font-size: 2.2rem;
}

.stat-item-label {
    font-size: 0.8rem;
    text-align: center;
}

.stat-guesses {
    max-width: 280px;
    margin: 1rem auto;
}

.stat-guesses-title {
    text-align: center;
    color: var(--text-color);
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.stat-guesses-row {
    display: flex;
    font-size: 0.8rem;
    margin: 0.2rem 0;
}

.stat-guesses-label {
    padding: 0.2rem;
}

.stat-guesses-value {
    margin-left: 0.5rem;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.2rem;
    padding-right: 0.6rem;
    text-align: right;
    font-weight: bold;
}

.nightmode .stat-guesses-value {
    background-color: rgba(255, 255, 255, 0.5);
}

.stat-guesses-value.green {
    background-color: var(--correct-color);
}

.btn.share {
    background-color: var(--correct-color);
}

.snackbar {
    position: fixed;
    top: 5rem;
    width: fit-content;
    background-color: var(--text-color);
    color: var(--background-color);
    padding: 1rem;
    border-radius: 0.5rem;
    transform: translateX(-50%);
    left: 50%;
    font-weight: bold;
    z-index: 11;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s;
}

.snackbar.show {
    opacity: 1;
    transition: opacity 0.2s;
}

button.icon-button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: #999;
    margin-left: 1rem;
}